<template>
  <section class="bg-light h-full">
    <div class="container py-8">

      <div class="text-primary font-display font-bold text-5xl text-center mb-4">
        Publicaciones
      </div>

      <div class="text-primary text-center mb-8">
        Seleccionar los artículos para saber más acerca de la ciencia detrás de los productos de Friso.
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">

        <div 
          class="item rounded-xl  flex flex-col justify-between cursor-pointer" 
          v-for="(item, idx) in list"
          :key="`item-${idx}`"
          @click="thumbClick(idx)"
        >
          <div
            class="text-md leading-5 text-white p-4 rounded-t-xl bg-cover bg-center flex-grow title"
            v-html="item.title"
          />
          <div
            class="text-xs leading-3 text-primary p-4 rounded-b-xl bg-white subtitle"
            v-html="item.subtitle"
          />
        </div>

      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Publications",
  setup() {

    const list = [
      {
        title: `Disminución de la Aparición de Síntomas Gastrointestinales en Lactantes Chinos Alimentados con una Fórmula Mínimamente Procesada Disponible Comercialmente: Un Estudio de Observación Transversal`,
        subtitle: `Xiao Yang Sheng, Vanitha Buthmanaban, Glenn A.A. van Lieshout y Panam Parikh`,
        file: `2020-sheng-et-al-jnme-disminucion-de-sintomas-gastrointestinales-en-lactantes-alimentados-con-una-formula-minimamente-procesada.pdf`,
      },
      {
        title: `La Importancia de la Lactosa en la Dieta Humana: Resultados de una Reunión de Consenso Mexicano`,
        subtitle: `Enrique Romero-Velarde, Dagoberto Delgado-Franco, Mariana García-Gutiérrez, Carmen Gurrola-Díaz, Alfredo Larrosa-Haro, Ericka Montijo-Barrios, Frits AJ Muskiet, Belinda Vargas-Guerrero y Jan Geurts`,
        file: `consenso-de-lactosa-traduccion-documento.pdf`,
      },
      {
        title: `Influence of micellar calcium phosphate on in vitro gastric coagulation and digestion of milk proteins in infant formula model systems`,
        subtitle: `Thorn Huppertz y Tim T. Lambers`,
        file: `huppertz-2020-influence-of-calcium-phosphate-on-in-vitro-coagulation-and-digestion-of-milk.pdf`,
      },
      {
        title: `Cómo el procesamiento puede afectar la digestión de proteínas de la leche y los resultados fisiológicos generales: una revisión sistemática`,
        subtitle: `Glenn A. A. van Lieshout, Tim T. Lambers, Marjolijn C. E., Bragt y Kasper A. Hettinga`,
        file: `lieshout-2019-como-el-procesamiento-puede-afectar-la-digestion-de-proteinas-de-la-leche-y-los-resultados-fisiologicos-generales.pdf`,
      },
      {
        title: `Infant milk formulas differ regarding their allergenic activity and induction of T-cell and cytokine responses`,
        subtitle: `H. Hochwallner, U. Schulmeister, I. Swoboda, M. Focke-Tejkl, R. Reininger, V. Civaj, R. Campana, J. Thalhamer, S. Scheiblhofer, N. Balic, F. Horak, M. Ollert, N. G. Papadopoulos, S. Quirce, Z. Szepfalusi, U. Herz, E. A. F. van Tol y S. Spitzauer & R. Valenta`,
        file: `hochwallner-2016-infant-milk-formuls-differ-regrding-their-allergenic-activity-and-induction-of-t-cell-and-cytokine-responses.pdf`,
      },
    ];

    const thumbClick = (idx: number) => {
      const item = list[idx];
      const imageRepo = 'https://api.metacriacoes.com/friesland-campina/app-friso/v1/?action=getFile&type=doc&file=publications/';
      const fileUrl = imageRepo + item['file'];
      window.open( fileUrl );
    }

    return {
      list,
      thumbClick,
    }
  }
});
</script>

<style scoped lang="scss">
.item {
  .title {
    background-image: url('/img/publications/item-bg.png');
  }
}
</style>